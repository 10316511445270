<template>
  <component :is="dealerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="warning"
      :show="dealerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching dealer data
      </h4>
      <div class="alert-body">
        No dealer found with this dealer id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-dealers-list'}"
        >
          Dealer List
        </b-link>
        for other dealers.
      </div>
    </b-alert>

    <transition
      name="fade"
      mode="out-in"
    >
      <dealers-edit-form
        v-if="dealerData"
        :dealer-data="dealerData"
        @submit="submit"
      />
      <div
        v-else
        class="text-center"
      >
        <b-spinner style="width: 4em; height: 4em;" />
      </div>
    </transition>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DealersEditForm from './DealersEditForm.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    DealersEditForm,
  },
  data() {
    return {
      dealerData: null,
    }
  },
  beforeMount() {
    this.$store.dispatch('dealer/fetchDealer', { id: router.currentRoute.params.id })
      .then(response => {
        if (response && response.status === 200) {
          this.dealerData = response.data
        } else {
          let error = response && response.data['hydra:description'] ? response.data['hydra:description'] : null
          switch (error) {
            case 'Not Found':
              error = 'Nie znaleziono dealera.'
              break
            default:
              error = 'Serwer nie odpowiada.'
              break
          }
          this.$root.$emit(
            'global:error',
            `${error}`,
          )
          this.$router.replace('/dealers/list')
        }
      })
  },
  methods: {
    submit() {
      const payload = { ...this.dealerData }

      this.loading = true
      store
        .dispatch('dealer/editDealer', { id: this.dealerData.id, payload })
        .then(response => {
          if (response && response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Zmieniono dane dealera',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push('/dealers/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Nie udało się zmodyfikować danych dealera.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: response ? response.data['hydra:description'] : null,
              },
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>

</style>
