<template>
  <b-table
    ref="refUserListTable"
    class="position-relative"
    :items="usersList"
    responsive
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="Nie znaleziono managerów."
    :sort-desc.sync="isSortDirDesc"
    :busy="loading"
    tbody-tr-class="cursor-pointer"
    hover
    bordered
    @row-clicked="editUserRedirect"
  >
    <template
      #table-busy
    >
      <div class="text-center">
        <b-spinner class="align-middle mr-1" /> Pobieranie listy managerów
      </div>
    </template>

    <!-- Column: Department -->
    <template #cell(department)="{item}">
      <b-badge
        v-for="department in item.departments"
        :key="department"
        pill
        variant="light-primary"
      >
        {{ resolveDepartment(department) }}
      </b-badge>
      {{ !item.departments || !item.departments.length ? ' - ' : null }}
    </template>
  </b-table>
</template>

<script>
import {
  BTable, BBadge, BSpinner,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'

export default {
  components: {
    BTable, BBadge, BSpinner,
  },
  props: {
    dealer: { type: Object, required: true },
  },
  data() {
    return {
      loading: true,
      isSortDirDesc: false,
      sortBy: 'lastName',
      tableColumns: [
        { key: 'lastName', sortable: true, label: 'Nazwisko' },
        { key: 'firstName', label: 'Imię' },
        { key: 'email', label: 'Email' },
        { key: 'department', label: 'Dział' },
      ],
    }
  },
  computed: {
    usersList() {
      const list = this.$store.state.user.list ? this.$store.state.user.list['hydra:member'] : []
      return list
    },
  },
  beforeMount() {
    this.$store.dispatch('user/fetchList', {
      params: {
        dealer: this.dealer.id,
        roles: 'ROLE_MANAGER',
      },
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    resolveDepartment,
    editUserRedirect(user) {
      this.$router.push(`/users/edit/${user.id}`)
    },
  },
}
</script>

<style>

</style>
