var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"editDealer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h3',[_vm._v("Dane podstawowe")]),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Nazwa dealera","rules":"required","autofocus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nazwa dealera","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.name),callback:function ($$v) {_vm.$set(_vm.dealerData, "name", $$v)},expression:"dealerData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Numer dealera","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numer dealera","label-for":"dealerNo"}},[_c('b-form-input',{attrs:{"id":"dealerNo","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.dealerNo),callback:function ($$v) {_vm.$set(_vm.dealerData, "dealerNo", $$v)},expression:"dealerData.dealerNo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required|nip|length:10","custom-messages":{nip:'Nieprawidłowy numer NIP.', length: 'Wprowadź NIP bez myślników.'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"nip"}},[_c('b-form-input',{attrs:{"id":"nip","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.nip),callback:function ($$v) {_vm.$set(_vm.dealerData, "nip", $$v)},expression:"dealerData.nip"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}}),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Ulica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ulica","label-for":"address.street"}},[_c('b-form-input',{attrs:{"id":"address.street","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.address.street),callback:function ($$v) {_vm.$set(_vm.dealerData.address, "street", $$v)},expression:"dealerData.address.street"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Nr domu / nr lokalu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nr domu / nr lokalu","label-for":"address.streetNo"}},[_c('b-form-input',{attrs:{"id":"address.streetNo","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.address.streetNo),callback:function ($$v) {_vm.$set(_vm.dealerData.address, "streetNo", $$v)},expression:"dealerData.address.streetNo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Kod pocztowy","rules":"required|regex:^\\d{2}-\\d{3}$","custom-messages":{regex: 'Nieprawidłowy format kodu pocztowego'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kod pocztowy","label-for":"address.postCode"}},[_c('b-form-input',{attrs:{"id":"address.postCode","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.address.postCode),callback:function ($$v) {_vm.$set(_vm.dealerData.address, "postCode", $$v)},expression:"dealerData.address.postCode"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Miasto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Miasto","label-for":"address.city"}},[_c('b-form-input',{attrs:{"id":"address.city","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.address.city),callback:function ($$v) {_vm.$set(_vm.dealerData.address, "city", $$v)},expression:"dealerData.address.city"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('h3',{staticClass:"mt-2 mb-1"},[_vm._v(" Managerowie dealera ")]),_c('dealers-manager-list',{attrs:{"dealer":_vm.dealerData}}),_c('h3',{staticClass:"mt-2"},[_vm._v(" Dane do faktury ")]),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Nazwa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nazwa","label-for":"invoiceData.name"}},[_c('b-form-input',{attrs:{"id":"invoiceData.name","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.invoiceData.name),callback:function ($$v) {_vm.$set(_vm.dealerData.invoiceData, "name", $$v)},expression:"dealerData.invoiceData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required|nip|length:10","custom-messages":{nip:'Nieprawidłowy numer NIP.', length: 'Wprowadź NIP bez myślników.'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"invoiceData.nip"}},[_c('b-form-input',{attrs:{"id":"invoiceData.nip","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.invoiceData.nip),callback:function ($$v) {_vm.$set(_vm.dealerData.invoiceData, "nip", $$v)},expression:"dealerData.invoiceData.nip"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}}),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Ulica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ulica","label-for":"invoiceData.street"}},[_c('b-form-input',{attrs:{"id":"invoiceData.street","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.invoiceData.street),callback:function ($$v) {_vm.$set(_vm.dealerData.invoiceData, "street", $$v)},expression:"dealerData.invoiceData.street"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Nr domu / nr lokalu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nr domu / nr lokalu","label-for":"invoiceData.streetNo"}},[_c('b-form-input',{attrs:{"id":"invoiceData.streetNo","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.invoiceData.streetNo),callback:function ($$v) {_vm.$set(_vm.dealerData.invoiceData, "streetNo", $$v)},expression:"dealerData.invoiceData.streetNo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Kod pocztowy","rules":"required|regex:^\\d{2}-\\d{3}$","custom-messages":{regex: 'Nieprawidłowy format kodu pocztowego'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kod pocztowy","label-for":"invoiceData.postCode"}},[_c('b-form-input',{attrs:{"id":"invoiceData.postCode","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.invoiceData.postCode),callback:function ($$v) {_vm.$set(_vm.dealerData.invoiceData, "postCode", $$v)},expression:"dealerData.invoiceData.postCode"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Miasto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Miasto","label-for":"invoiceData.city"}},[_c('b-form-input',{attrs:{"id":"invoiceData.city","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.dealerData.invoiceData.city),callback:function ($$v) {_vm.$set(_vm.dealerData.invoiceData, "city", $$v)},expression:"dealerData.invoiceData.city"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit","disabled":invalid || _vm.loading}},[_vm._v(" Zapisz zmiany ")]),_c('b-button',{attrs:{"variant":"outline-secondary","to":"/dealers/list","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Anuluj ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }