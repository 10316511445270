export default function resolveDepartment(department) {
  if (department === 'FLEET_DEPARTMENT') return 'Dział floty'
  if (department === 'NEW_CARS_DEPARTMENT') return 'Dział samochodów osobowych'
  if (department === 'COMMERCIAL_VEHICLES_DEPARTMENT') return 'Dział samochodów dostawczych'
  if (department === 'USED_CARS_DEPARTMENT') return 'Dział samochodow używanych'
  if (department === 'SERVICE_DEPARTMENT') return 'Dział serwisu'
  if (department === 'RECEPTION_DEPARTMENT') return 'Recepcja'
  if (department === 'FINANCE_DEPARTMENT') return 'Dział finansów'
  return 'Nieznany dział'
}
