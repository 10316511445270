<template>
  <div>
    <validation-observer
      ref="editDealer"
      #default="{ invalid }"
    >
      <!-- Dealer Info: Input Fields -->
      <b-form @submit.prevent="submit">
        <h3>Dane podstawowe</h3>
        <hr>
        <b-row>
          <!-- Nazwa dealera -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Nazwa dealera"
            rules="required"
            autofocus
          >
            <b-form-group
              label="Nazwa dealera"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="dealerData.name"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <!-- Numer dealera -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Numer dealera"
            rules="required"
          >
            <b-form-group
              label="Numer dealera"
              label-for="dealerNo"
            >
              <b-form-input
                id="dealerNo"
                v-model="dealerData.dealerNo"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <!-- NIP -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="NIP"
            rules="required|nip|length:10"
            :custom-messages="{nip:'Nieprawidłowy numer NIP.', length: 'Wprowadź NIP bez myślników.'}"
          >
            <b-form-group
              label="NIP"
              label-for="nip"
            >
              <b-form-input
                id="nip"
                v-model="dealerData.nip"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <!-- Ulica -->
          <b-col
            cols="12"
            md="3"
          />
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Ulica"
            rules="required"
          >
            <b-form-group
              label="Ulica"
              label-for="address.street"
            >
              <b-form-input
                id="address.street"
                v-model="dealerData.address.street"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>

          <!-- Nr domu / nr lokalu -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Nr domu / nr lokalu"
            rules="required"
          >
            <b-form-group
              label="Nr domu / nr lokalu"
              label-for="address.streetNo"
            >
              <b-form-input
                id="address.streetNo"
                v-model="dealerData.address.streetNo"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>

          <!-- Kod pocztowy -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Kod pocztowy"
            rules="required|regex:^\d{2}-\d{3}$"
            :custom-messages="{regex: 'Nieprawidłowy format kodu pocztowego'}"
          >
            <b-form-group
              label="Kod pocztowy"
              label-for="address.postCode"
            >
              <b-form-input
                id="address.postCode"
                v-model="dealerData.address.postCode"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <!-- Miasto -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Miasto"
            rules="required"
          >
            <b-form-group
              label="Miasto"
              label-for="address.city"
            >
              <b-form-input
                id="address.city"
                v-model="dealerData.address.city"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
        </b-row>

        <h3 class="mt-2 mb-1">
          Managerowie dealera
        </h3>

        <dealers-manager-list :dealer="dealerData" />

        <h3 class="mt-2">
          Dane do faktury
        </h3>
        <hr>
        <b-row>

          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Nazwa"
            rules="required"
          >
            <b-form-group
              label="Nazwa"
              label-for="invoiceData.name"
            >
              <b-form-input
                id="invoiceData.name"
                v-model="dealerData.invoiceData.name"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="NIP"
            rules="required|nip|length:10"
            :custom-messages="{nip:'Nieprawidłowy numer NIP.', length: 'Wprowadź NIP bez myślników.'}"
          >
            <b-form-group
              label="NIP"
              label-for="invoiceData.nip"
            >
              <b-form-input
                id="invoiceData.nip"
                v-model="dealerData.invoiceData.nip"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Ulica"
            rules="required"
          >
            <b-form-group
              label="Ulica"
              label-for="invoiceData.street"
            >
              <b-form-input
                id="invoiceData.street"
                v-model="dealerData.invoiceData.street"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>

          <!-- Nr domu / nr lokalu -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Nr domu / nr lokalu"
            rules="required"
          >
            <b-form-group
              label="Nr domu / nr lokalu"
              label-for="invoiceData.streetNo"
            >
              <b-form-input
                id="invoiceData.streetNo"
                v-model="dealerData.invoiceData.streetNo"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>

          <!-- Kod pocztowy -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Kod pocztowy"
            rules="required|regex:^\d{2}-\d{3}$"
            :custom-messages="{regex: 'Nieprawidłowy format kodu pocztowego'}"
          >
            <b-form-group
              label="Kod pocztowy"
              label-for="invoiceData.postCode"
            >
              <b-form-input
                id="invoiceData.postCode"
                v-model="dealerData.invoiceData.postCode"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>
          <!-- Miasto -->
          <b-col
            cols="12"
            md="3"
          ><validation-provider
            #default="{errors}"
            name="Miasto"
            rules="required"
          >
            <b-form-group
              label="Miasto"
              label-for="invoiceData.city"
            >
              <b-form-input
                id="invoiceData.city"
                v-model="dealerData.invoiceData.city"
                :state="errors.length > 0 ? false:null"
                trim
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider></b-col>

        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
          :disabled="invalid || loading"
        >
          Zapisz zmiany
        </b-button>
        <b-button
          variant="outline-secondary"
          to="/dealers/list"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Anuluj
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import DealersManagerList from './DealersManagerList.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    DealersManagerList,
  },

  props: {
    dealerData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      loading: false,
    }
  },
  computed: {

  },
  methods: {
    submit() {
      this.$refs.editDealer.validate().then(valid => {
        if (valid) {
          this.$emit('submit')
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
